<template>
  <div>
    <div class="header">
      <i class="el-icon-data-analysis">
        <span style="margin-left:14px;">数据详情</span>
      </i>
    </div>
    <div class="header-box">
      <div class="box4">
        <div class="box-title">
          <span>签约经纪人数</span>
        </div>
        <div class="box-border">
          <div class="border-left">
            <img src="../../assets/home_icon.png" alt="" />
          </div>
          <div class="box-content">
            <div @click="goAgent()">
              <div>累计</div>
              <div>{{ data.agent_num_total }}</div>
            </div>
            <div @click="goAgent()">
              <div>当月新增</div>
              <div>{{ data.agent_num_month }}</div>
            </div>
            <div @click="goAgent()">
              <div>当日新增</div>
              <div>{{ data.agent_num_today }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="box2">
        <div class="box-title">
          <span>签约企业数</span>
        </div>
        <div class="box-border">
          <div class="border-left">
            <img src="../../assets/home_icon2.png" alt="" />
          </div>
          <div class="box-content">
            <div @click="goAgentEnterprise()">
              <div>累计</div>
              <div>{{ data.enterprise_num_total }}</div>
            </div>
            <div @click="goAgentEnterprise()">
              <div>当月新增</div>
              <div>{{ data.enterprise_num_month }}</div>
            </div>
            <div @click="goAgentEnterprise()">
              <div>当日新增</div>
              <div>{{ data.enterprise_num_today }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="box1">
        <div class="box-title">
          <span>跟进企业数</span>
        </div>
        <div class="box-border">
          <div class="border-left">
            <img src="../../assets/home_icon3.png" alt="" />
          </div>
          <div class="box-content">
            <div @click="goFollow()">
              <div>跟进企业数量</div>
              <div>{{ data.follow_num }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="box3">
        <div class="box-title">
          <span>业务发生额</span>
        </div>
        <div class="box-border">
          <div class="border-left">
            <img src="../../assets/home_icon4.png" alt="" />
          </div>
          <div class="box-content">
            <div @click="goAgentEnterpriseStastics()">
              <div>累计(￥)</div>
              <div>{{ data.money_total | money }}</div>
            </div>
            <div @click="goAgentEnterpriseStastics()">
              <div>本月(￥)</div>
              <div>{{ data.money_total_month | money }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="block">
      <Echart4></Echart4>
    </div>
    <div class="block">
      <Echart6></Echart6>
    </div>
    <div class="block">
      <Echart7></Echart7>
    </div>
    <div class="block">
      <Echart5></Echart5>
    </div>
  </div>
</template>

<script>
// import { loadjs } from "../../lib/load"
import { api } from '/src/api/base';
export const dashboardGeneral = api()('dashboard.agent.general.json');

export default {
  components: {
    Echart4: () => import('./echart4.vue'),
    Echart5: () => import('./echart5.vue'),
    Echart6: () => import('./echart6.vue'),
    Echart7: () => import('./echart7.vue'),
  },
  data () {
    return {
      data: {},
    };
  },
  async created () {
    this.getData();
  },
  methods: {
    async getData () {
      this.data = await dashboardGeneral();
    },
    goAgent () {
      this.$router.push({
        path: '/system/agent/businessPersonnelList',
      });
    },
    goAgentEnterprise () {
      this.$router.push({
        path: '/system/agent/agentEnterprise',
      });
    },
    goFollow () {
      this.$router.push({
        path: '/system/followEnterprise/list',
      });
    },
    goAgentEnterpriseStastics () {
      this.$router.push({
        path: '/system/agent/agentEnterpriseStastics',
      });
    },
  },
};
</script>

<style scoped lang="scss">
.header-box {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 40px -20px;
  color: #fff;

  >div {
    border-radius: 10px;
    padding: 21px 30px;
    text-align: center;
    flex-grow: 1;
    margin: 0 20px;
    // min-width: 160px;
  }

  .box-title {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 14px;

    i {
      font-size: 23px;
    }

    span {
      margin-left: 6px;
    }
  }

  .box-border {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;

    .border-left {
      width: 80px;
      height: 80px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .box-content {
    width: 60%;
    font-size: 15px;

    div {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    >div>div {
      line-height: 26px;
      font-size: 17px;
    }

    .box-content:last-child {
      font-weight: bold;
      margin-top: 6px;
    }
  }

  .box1 {
    background-image: linear-gradient(to bottom left, #f5a9ab, #ae70f4);
    height: 150px;
    flex: 1;
    box-shadow: 2px 5px 15px #c07fe1;
  }

  .box2 {
    background-image: linear-gradient(to bottom left, #f9cbbb, #f66460);
    height: 150px;
    flex: 1;
    box-shadow: 2px 5px 15px #f78079;
  }

  .box3 {
    background-image: linear-gradient(to bottom left, #89eded, #7969e5);
    height: 150px;
    flex: 1;
    box-shadow: 2px 5px 15px #82acea;
  }

  .box4 {
    background-image: linear-gradient(to bottom left, #89eded, #b159d5);
    height: 150px;
    flex: 1;
    box-shadow: 2px 5px 15px #a683dc;
  }

  .box5 {
    background-image: linear-gradient(to bottom left, #f7bee4, #fa4691);
    height: 150px;
    flex: 1;
  }

  .block {
    margin-bottom: 40px;
  }
}
</style>
